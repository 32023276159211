import { Box } from '@mui/material';
import React from 'react';
import EndInfoBanner from '../EndInfoBanner';

const textUp = 'Potwierdź tożsamość klienta';

const textDown = (
  <>
    ...zanim obsłużysz jego transakcje lub <br />
    udzielisz mu dostępu do swoich <strong>zasobów</strong> i <strong>usług</strong>
  </>
);

const textButton = <> Zarejestruj&nbsp;się </>;

const pageLink = 'https://verify.fiberpay.pl/register';

const Pricing = () => (
  <>
    <Box
      sx={{
        height: { xs: '32px', sm: '96px' },
        width: '100%',
        zIndex: -1,
        background: '#EBF2FA',
      }}
    />
    <EndInfoBanner
      textGridhWidth={8}
      textUp={textUp}
      textUpFoWe={700}
      textDown={textDown}
      textDoFoWe={500}
      textButton={textButton}
      pageLink={pageLink}
    />
  </>
);

export default Pricing;
