import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import { EventNote, Message, MonetizationOn } from '@mui/icons-material';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import Features from '../Features';

const products = [
  {
    name: 'Dane konta bankowego',
    description: (
      <>
        Po zalogowaniu się użytkownika do konta bankowego potwierdzimy zgodność
        podanych przez użytkownika danych z imieniem i nazwiskiem właściciela
        konta
      </>
    ),
    icon: <Message sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Przelew weryfikacyjny',
    description: (
      <>
        Po przelewie niewielkiej kwoty na nasze konto potwierdzimy zgodność
        danych użytkownika z imieniem i nazwiskiem nadawcy przelewu
      </>
    ),
    icon: <MonetizationOn sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Profil Zaufany',
    description: (
      <>
        Po zalogowaniu się użytkownika do Profilu Zaufanego (ePUAP)
        zweryfikujemy zgodność danych użytkownika z imieniem i nazwiskiem
        właściciela Profilu
      </>
    ),
    icon: <EventNote sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberaml/',
  },
];

const ProductsVerification = () => (
  <LandingSection
    columnText={false}
    sx={{
      marginTop: (theme) => theme.spacing(12),
      position: 'relative',
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Mechanizmy weryfikacji'
    title='Wybierz spośród dostępnych sposobów sprawdzenia tożsamości użytkownika'
    actionButtonText='Sprawdź już teraz'
    actionButtonUrl={'https://verify.fiberpay.pl/register/'}
    isActionUrlExternal={true}
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default ProductsVerification;
