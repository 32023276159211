import React from 'react';
import TextTransform from '../TextTransform';

const TEXTS = [
  'Chcesz zapobiec wykorzystaniu Twojej aplikacji przez oszustów?',
  'Jesteś zobowiązany do identyfikacji klientów wymogami prawa?',
  'Chcesz obsługiwać tylko sprawdzonych, nieanonimowych użytkowników?',
];

const Landing = () => {
  return <TextTransform text={TEXTS} backColor={'#EBF2FA'} />;
};

export default Landing;
