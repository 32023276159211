import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import {
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
} from '@mui/icons-material';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import Features from '../Features';

const products = [
  {
    name: 'Zlecenie weryfikacji',
    description: (
      <>Twoja aplikacja tworzy zlecenie potwierdzenia tożsamości użytkownika via API FiberID</>
    ),
    icon: <Filter1 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Przekierowanie do FiberID',
    description: (
      <>Twoja aplikacja przekierowuje użytkownika do FiberID w celu dokonania weryfikacji</>
    ),
    icon: <Filter2 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Ankieta użytkownika',
    description: (
      <>
        Użytkownik podaje swoje dane - listę wymaganych informacji ustalasz w specyfikacji zlecenia
      </>
    ),
    icon: <Filter3 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberaml/',
  },
  {
    name: 'Uzyskanie danych użytkownika',
    description: (
      <>
        Użytkownik udostępnia swoje dane - udzielając zgody na dostęp do konta bankowego, wykonując
        przelew weryfikacyjny lub podpisując formularz swoim Profilem Zaufanym
      </>
    ),
    icon: <Filter4 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fibertoken/',
  },
  {
    name: 'Potwierdzenie zgodności',
    description: (
      <>
        FiberID porównuje dane, które użytkownik podał w ankiecie z uzyskanymi z wiarygodnego źródła
        - konta lub przelewu bankowego, Profilu Zaufanego
      </>
    ),
    icon: <Filter5 sx={{ fontSize: 60 }} />,
    url: '/products/fibertoken/',
  },
  {
    name: 'Przekazanie wyników',
    description: (
      <>
        Twoja aplikacja otrzymuje wynik potwierdzenia tożsamości wraz danymi, które użytkownik podał
        w ankiecie FiberID
      </>
    ),
    icon: <Filter6 sx={{ fontSize: 60 }} />,
    url: '/products/fibertoken/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Jak to działa'
    title='Proces weryfikacji tożsamości Twoich użytkowników w 6 prostych krokach'
    // colLeftText="Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
    //       ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
    //       dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej."
    // colRightText="Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
    //       obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
    //       dostawców."
    actionButtonText='Sprawdź już teraz'
    actionButtonUrl={'https://verify.fiberpay.pl/register/'}
    isActionUrlExternal={true}
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={false}
    />
  </LandingSection>
);

export default Products;
