import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Navbar from '../../navbar/navbar';
import { CallToActionButton } from '../../common/Button';
import BaseContainer from '../../common/BaseContainer';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import LandingBackgroundFiberID from '../../images/Landing-fiberid';

const subtitles = [
  <>
    FiberID API pozwala Twojej aplikacji <strong>zweryfikować</strong> tożsamość
    osób, które obsługujesz -
  </>,
  <>
    bez konieczności osobistego kontaktu, <strong>automatycznie</strong> i{' '}
    <strong>bezpiecznie</strong>
  </>,
];

const HeroBr = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('800')]: {
    display: 'none',
  },
}));

const BoxWrapper = styled('div')(() => ({
  color: 'white',
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(135deg, #3180de, #1e5abb, #69b3e6)',
    opacity: 0.75,
  },
}));

const Hero = () => (
  <LandingBackgroundFiberID>
    <BoxWrapper>
      {/* <HeroImageWrapper sx={{ display: { xs: "none", xl: "block" } }}>
        <StaticImage
          src="../../../images/fiberID-phone.png"
          alt="Hero image"
          placeholder="blurred"
          height={650}
          // paddingRight={100}
        />
      </HeroImageWrapper> */}
      <BaseContainer>
        <Navbar loginLink={'https://verify.fiberpay.pl/login'} />
        <Typography
          variant='h2'
          component='h1'
          sx={{
            fontSize: { xs: '3rem', sm: '4rem', md: '5rem', lg: '5.4rem' },
            fontWeight: 'bold',
            mt: 8,
            zIndex: 100,
            position: 'relative',
          }}
          color='white'
        >
          Sprawdź, czy <HeroBr />
          użytkownik jest tym <HeroBr /> za kogo się podaje
        </Typography>
        <Grid container sx={{ my: 4 }} spacing={2}>
          {subtitles.map((subtitle, idx) => (
            <Grid item xs={12} key={idx}>
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'white',
                  fontWeight: 'light',
                  position: 'relative',
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-start' },
          }}
        >
          <a
            href='https://verify.fiberpay.pl/register'
            style={{ textDecoration: 'none' }}
          >
            <CallToActionButton
              endIcon={<ArrowForwardOutlinedIcon />}
              sx={{ mt: 6, mb: { xs: 8, lg: 10 } }}
            >
              Zarejestruj się
            </CallToActionButton>
          </a>
        </Box>
      </BaseContainer>
    </BoxWrapper>
  </LandingBackgroundFiberID>
);

export default Hero;
