import React from 'react';
import Footer from '../../components/common/Footer';
import Hero from '../../components/landing/fiberid/Hero';
import Landing from '../../components/landing/fiberid/Landing';
import Pricing from '../../components/landing/fiberid/Pricing';
import Products from '../../components/landing/fiberid/Products';
import ProductsVerification from '../../components/landing/fiberid/ProductsVerification';
import Seo from '../../seo';

const IndexPage = () => {
  return (
    <>
      <Seo
        title='Weryfikacja tożsamości klienta on-line FiberID | Fiberpay '
        description='FiberID API pozwala Twojej aplikacji zweryfikować tożsamość osób, które 
        obsługujesz - bez konieczności osobistego kontaktu, automatycznie i bezpiecznie'
        metaImage='meta_image_fiberid.png'
        currentUrl='https://fiberpay.pl/produkty/silne-uwierzytelnienie-2FA-fibertoken'
      />
      <Hero />
      <Landing />
      <Products />
      <ProductsVerification />
      <Pricing />
      <Footer />
    </>
  );
};

export default IndexPage;
